import axios from 'axios'
import { baseUrl } from './base'
import Toast from 'vant/es/toast'
import 'vant/es/toast/style'
export const requestPostParam = (url, data) => {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 2000000
  })
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + url,
      params: data,
      method: 'post'
    }).then(({ data }) => {
      resolve(data)
      Toast.clear()
    }).catch(err => {
      reject(err)
      Toast.clear()
    })
  })
}
export const requestPostBody = (url, data) => {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 2000000
  })
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + url,
      data,
      method: 'post'
    }).then(({ data }) => {
      resolve(data)
      Toast.clear()
    }).catch(err => {
      reject(err)
      Toast.clear()
    })
  })
}
export const requestGetParam = (url, data) => {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 2000000
  })
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + url,
      params: data,
      method: 'post'
    }).then(({ data }) => {
      resolve(data)
      Toast.clear()
    }).catch(err => {
      reject(err)
      Toast.clear()
    })
  })
}
