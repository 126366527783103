<template>
  <router-view/>
</template>

<style lang="scss">
html,body,#app{
  position: relative;
  width: 100%;
  height: 100%;
}

</style>
