<template>
  <div class="home">
    <div class="home-body" >
          <table>
            <colgroup>
              <col width="60px">
              <col width="100px">
              <col width="100px">
              <col width="190px">
              <col width="200px">
              <col width="160px">
              <col width="120px">
              <col width="130px">
              <col width="140px">
              <col width="120px">
              <col width="110px">
              <col width="110px">
              <col width="150px">
              <col width="150px">
            </colgroup>
            <thead>
              <tr >
                <th>序号</th>
                <th v-for="item in data.keys" :key="item.key">{{item.value}}</th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="(item,idx) in data.list" :key="item">
                <td>{{idx+1}}</td>
                <td style="padding: 0 8px" v-for="row in data.keys" :key="row.key">{{item[row.key]}}</td>
              </tr>
            </tbody>
          </table>

    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { requestGetParam } from '../../util/request'
import { useRoute } from 'vue-router'
export default {
  name: 'Home',
  setup () {
    const data = reactive({
      list: [],
      keys: [
        { key: 'ymd', value: '日期' },
        { key: 'testAppName', value: '应用名称' },
        { key: 'domainName', value: '域名' },
        { key: 'request_url', value: '测试链接' },
        { key: 'ip', value: 'ip' },
        { key: 'tcp_avgDelay', value: 'tcp时延（ms）' },
        { key: 'tcp_avgSuccRate', value: 'tcp建链成功率(%)' },
        { key: 'http_avgDelay', value: 'http平均时延（ms）' },
        { key: 'http_avgSuccRate', value: 'get成功率（%）' },

        // { key: 'playFirstDelay', value: '首播时延（ms）' },
        // { key: 'avgSpeed', value: '平均速率(kbps)' },
        { key: 'province', value: '省份' },
        { key: 'city', value: '地市' },
        { key: 'imei1', value: 'imei1' },
        { key: 'imei2', value: 'imei2' }
      ]
    })
    const route = useRoute()
    const getFindTestAlarmInfoDetail = () => {
      // dayjs().format('YYYY-MM-DD')
      requestGetParam('/alarmList/listAlarmInfo', { infoId: route.query.infoId }).then(item => {
        data.list = item.msg
      })
    }
    onMounted(() => {
      getFindTestAlarmInfoDetail()
    })
    return { data }
  }
}
</script>
<style lang="scss" scoped>
.home{
  position: relative;
  width: 100%;
  height: 100%;
  .home-body{
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    background-color: #fff;
    height: 100%;
      table{
        width: 100%;
        table-layout: fixed;
        color: #444;
      }
      tr{
        font-size: 10px;

      }
      td{
        text-align: center;
        background-color: #cde3e3;
        border: 1px solid #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      td,th{
        padding: 10px 0;

      }
      thead{
        height: 31px;
        width: 100%;
        th{
          background-color: #9fcdcd;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
        }
      }

  }
}
</style>
